// Google Recaptcha v3
grecaptcha.ready(function () {
	$('form.mod-recaptcha').each(function () {
		var object = $(this);
		grecaptcha.execute('6LcHwakaAAAAAKr5dRiUPfW5x2KYMUY03eOWaulC', { action: 'homepage' }).then(function (token) {
			$('<input>').attr({
				type: 'hidden',
				name: 'g-recaptcha-response',
				value: token
			}).appendTo(object)
		});
	});
});

const body = document.querySelector('body');

// Helper function
let domReady = (cb) => {
	document.readyState === 'interactive' || document.readyState === 'complete'
		? cb()
		: document.addEventListener('DOMContentLoaded', cb);
};

domReady(() => {
	// Display body when DOM is loaded
	body.style.visibility = 'visible';
});

jQuery(document).ready(function ($) {
	search.load();
});

// Search
(function (search, $, undefined) {

	search.load = function () {
		$('body').on('keyup', '.sub-searchform .searchterm', debounce(function (e) {

			$('.sub-loading').fadeTo(300, 0.5);

			// Clear outputs
			$('.sub-error').html('');
			$('.sub-results ul.list-results').html('');

			$.ajax({
				method: 'POST',
				url: 'api/search/',
				data: { 'searchterm': $(this).val() }
			}).done(function (response) {

				// Decode json response
				response = JSON.parse(response);

				// If we failed to retrieve any results
				if (response[0] == 'failure') {
					// Display error
					$('.sub-error').html(response['message']);
					$('.sub-results').removeClass('mod-show');
					$('body').removeClass('noscroll');

				}
				else {
					// Loop through results
					$.each(response, function (key, value) {
						// Output results as list items
						$('.sub-results ul.list-results').append('<li class="clearfix"><a class="title" href="' + value['link'] + '">' + value['title'] + '</a><span class="category">' + value['type'] + '</span></li>');
					});

					$('.sub-results').addClass('mod-show');
					$('body').addClass('noscroll');
				}
			});

			//hide loading overlay
			$('.sub-loading').fadeOut(300);
		}, 250));
	};
}(window.search = window.search || {}, jQuery));

// Mobile Nav
$('.mob-hamburger').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('body').toggleClass('no-scroll');
	$('#nav > ul').toggleClass('mod-active');
});

$('.list-nav li.mod-dropdown > a').click(function (e) {
	e.preventDefault();

	$(this).next('ul.sub-sub-menu').toggleClass('mod-active');
});


// Slick Carousel for careers testimonials

if ($('#careers, #case-studies, #programmes').length > 0) {
	$('ul.list-testimonials').slick({
		dots: true,
		infinite: true,
		autoplay: false,
		fade: false,
		arrows: false,
		adaptiveHeight: true
	});

	// Stop all YouTube videos if going to the next slide
	$('ul.list-testimonials').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		var current = $(slick.$slides[currentSlide]);
		current.html(current.html());
	});
}

if ($('#case-studies-detail').length > 0) {
	$('ul.list-funders.mod-mobile').slick({
		arrows: true,
		autoplay: false,
		dots: false,
		fade: false,
		infinite: true,
		nextArrow: $('.nav-slider .sub-next'),
		prevArrow: $('.nav-slider .sub-prev'),
		slidesToScroll: 1,
		slidesToShow: 2
	});
}

// TODO: uncomment when pushing live
if ($('#home').length > 0) {
	$('.list-home-hero').slick({
		arrows: true,
		prevArrow: $('.sub-prev'),
		nextArrow: $('.sub-next'),
		autoplay: true,
		autoplaySpeed: 7000,
		dots: true,
		appendDots: $('.sub-dots'),
		fade: true,
		infinite: true,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 1000
	});
}

$(window).on('load', function () {
	if ($('#programmes').length > 0 && window.location.hash) {
		$('html, body').animate({ scrollTop: $(window.location.hash).offset().top - 50 }, 850);

		$('#programmes .list-sub-projects li' + window.location.hash).find('.sub-read-more').trigger('click');
	}
});

if ($('#contact').length) {
	// Change style of agreement checkbox on router order form
	$('input[type="checkbox"]').on('change', function () {

		$('.sub-terms').removeClass('mod-checked');

		if ($(this).is(":checked")) {
			$(this).parents('label').addClass('mod-checked');
			$(this).parents('.sub-terms').addClass('mod-checked');
		}
	});
}

// Shows more or less text on members profile cards
$('.list-the-team li a.sub-read-more').click(function (e) {
	// Prevents default action
	e.preventDefault();

	const $this = $(this),
		hasClass = $this.hasClass('less'),
		fullDesc = $this.parent().find('.sub-secondary-copy');

	// Toggle less class depending whether button has been clicked
	$this.toggleClass('less');

	// If button clicked has the class 'less' show 'More' text, if not, revert to 'Less' text.
	$this.text(hasClass ? 'More about ' + $this.data('name') : 'Less about ' + $this.data('name'));

	// Depending whether '.less' is active, change the display property.
	if (hasClass) {
		fullDesc.removeClass('mod-active');
	} else {
		fullDesc.addClass('mod-active');
	}
});


// Shows more or less text on members profile cards
$('.section-programmes .sub-read-more').click(function (e) {
	// Prevents default action
	e.preventDefault();

	const $this = $(this),
		hasClass = $this.hasClass('less'),
		fullDesc = $this.parent().find('.sub-full-copy');

	// Toggle less class depending whether button has been clicked
	$this.toggleClass('less');

	// If button clicked has the class 'less' show 'More' text, if not, revert to 'Less' text.
	$this.text(hasClass ? 'Read More' : 'Read Less');

	// Depending whether '.less' is active, change the display property.
	if (hasClass) {
		fullDesc.removeClass('mod-active');
	} else {
		fullDesc.addClass('mod-active');
	}
});

// Open search form
if ($('.header-main').length) {
	$('.sub-search .icon-search, .sub-search .sub-close, .sub-searchform .sub-close').on('click', function () {
		$('.sub-searchform').toggleClass('mod-active');
		$('.sub-results').removeClass('mod-show');
		$('body').removeClass('noscroll');
		$('.sub-searchform .searchterm').val('');
	});
}

// Line following under nav items
$(window).on('load resize', function () {
	// Main navigation line animation
	const underline = $('ul.list-nav .nav-track-line'),
		active = $('ul.list-nav li.active');

	// Default position of line if item is active
	function resetLine() {
		underline.css('left', active.position().left);
	}

	resetLine();

	// Move line to position of hovered item
	$('ul.list-nav li').hover(function () {
		underline.css('left', $(this).position().left);
	}, function () {
		// If mouse is removed from the item, reset back to last active item
		resetLine();
	});
});

// Show/hide cookie policy
$("body").on("click", ".section-cookies .btn-accept", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

$(".section-cookies .btn-decline").on("click", (ev) => {
	ev.preventDefault();

	$(".section-cookies").slideToggle();
});

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 10000);

		}
		else {
			console.log(response);
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});
}


// Google Maps

// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Respair', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "landscape", "stylers": [{ "saturation": -100 }, { "lightness": 60 }] }, { "featureType": "road.local", "stylers": [{ "saturation": -100 }, { "lightness": 40 }, { "visibility": "on" }] }, { "featureType": "transit", "stylers": [{ "saturation": -100 }, { "visibility": "simplified" }] }, { "featureType": "administrative.province", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "stylers": [{ "visibility": "on" }, { "lightness": 30 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ef8c25" }, { "lightness": 40 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi.park", "elementType": "geometry.fill", "stylers": [{ "color": "#b6c54c" }, { "lightness": 40 }, { "saturation": -40 }] }, {}],
			{ name: 'Styled Map' }
		);
	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 51.570316, -0.339984, 9, true);
	}
}


// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


document.addEventListener('DOMContentLoaded', () => {
	const body = document.querySelector('body');

	if ($('.js-modal-btn').length) {
		document.querySelectorAll('.js-modal-btn').forEach((element) => {
			element.addEventListener('click', (event) => {
				event.preventDefault();

				const modal = document.querySelector('.modal');

				if (modal === null) {
					console.error(
						'Download form button cannot be initialised without a .modal element on the page.'
					);

					return;
				}

				modal.classList.remove('mod-read-more-content-modal-hidden');

				const contentContainer = document.querySelector('#' + element.getAttribute('data-content-id'));

				modal.querySelector('.modal-content').innerHTML = contentContainer.innerHTML;

				body.classList.add('no-scroll');
			});
		});

		// Close button for modal
		document.querySelectorAll('.modal-close').forEach((element) => {
			element.addEventListener('click', () => {
				const modal = document.querySelector('.modal');

				body.classList.remove('no-scroll');
				element.parentElement.parentElement.classList.add('mod-read-more-content-modal-hidden');
				modal.classList.add('mod-read-more-content-modal-hidden');
			});
		});


		// Clicking outside the modal will close the modal
		window.onclick = function (event) {
			const modal = document.querySelector('.modal');

			if (event.target == modal) {
				modal.classList.add('mod-read-more-content-modal-hidden');
			}
		}
	}
});